import { getParameterByName } from "./getParameterByName";

function getApiOverride() {
  const overrideApiUrlParam = getParameterByName("overrideApiUrl");
  const overrideSocketUrlParam = getParameterByName("overrideSocketUrl");

  if (overrideApiUrlParam && overrideSocketUrlParam) {
    localStorage.setItem(
      "apiOverride",
      JSON.stringify({
        overrideApiUrl: overrideApiUrlParam,
        overrideSocketUrl: overrideSocketUrlParam,
      })
    );

    return {
      overrideApiUrl: overrideApiUrlParam,
      overrideSocketUrl: overrideSocketUrlParam,
    };
  }

  const stored = localStorage.getItem("apiOverride");

  if (stored === null) {
    return null;
  }

  try {
    const overrides = JSON.parse(stored);
    if (overrides.overrideApiUrl && overrides.overrideSocketUrl) {
      return {
        overrideApiUrl: overrides.overrideApiUrl,
        overrideSocketUrl: overrides.overrideSocketUrl,
      };
    }

    return null;
  } catch {
    localStorage.removeItem("apiOverride");
    return null;
  }
}

function weightedRandom<T>(items: T[], weights: number[]) {
  for (let i = 0; i < weights.length; i++) {
    weights[i] += weights[i - 1] ?? 0;
  }

  for (let i = 0; i < weights.length; i++) {
    if (weights[i] > Math.random() * weights[weights.length - 1]) {
      return items[i];
    }
  }

  return items[items.length - 1];
}

const buildConsts = () => {
  const overrides = getApiOverride();
  const getApiUrl = (): string => {
    return typeof import.meta.env.API_URL2 === "string"
      ? weightedRandom([import.meta.env.API_URL, import.meta.env.API_URL2], [5, 1])
      : import.meta.env.API_URL;
  };

  return {
    dev: false,
    api: overrides?.overrideApiUrl ?? getApiUrl(),
    socketApi: overrides?.overrideSocketUrl || import.meta.env.SOCKET_URL,
    PRICE_PER_SEGMENT: 0.825,
    SEGMENT_AMOUNT: 6,
  };
};

export const Consts = buildConsts();
